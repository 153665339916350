/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
} from "@vuelidate/validators"
import { compareTwoStrings } from "string-similarity"
import { computed } from "vue"
import { useNamespacedGetters } from "vuex-composition-helpers"
import { useNamespacedState } from "vuex-composition-helpers/dist"

import { useValidations } from "@/composables"
import i18n from "@/i18n"
import {
  isBeforeToday,
  isBirthCert,
  isValidCIE,
  isValidCPF,
  isValidDate,
  isValidDNI,
  isValidID,
  isValidMobilePhone,
  isValidPassport,
  isValidPhone,
  isValidRUT,
  isYoungerThan110Years,
  olderThanEighteen,
  youngerThanEighteen,
} from "@/validators"

// eslint-disable-next-line
export function usePersonalInfoValidations({ countries, config }) {
  const { guest } = useNamespacedState("guest", ["guest"])
  const { groupId } = useNamespacedState("company", ["groupId"])
  const { additionalDocument } = useNamespacedState("parameters", [
    "additionalDocument",
  ])
  const { personalInfoConfig } = useNamespacedGetters("company", [
    "personalInfoConfig",
  ])
  const {
    defaultAdditionalTypes,
    foreignAdditionalTypes,
    minorsAdditionalTypes,
  } = useNamespacedGetters("parameters", [
    "defaultAdditionalTypes",
    "foreignAdditionalTypes",
    "minorsAdditionalTypes",
  ])

  const { withI18nMessage, getErrorMessages } = useValidations()

  const isValidDocument = (value) => {
    switch (guest.value.documents.documentTypeId) {
      case 0:
        return isBirthCert(value)
      case 1:
        return isValidCPF(value)
      case 2:
        return isValidCIE(value)
      case 3:
        return isValidID(value)
      case 4:
        return isValidPassport(value)
      case 5:
        return isValidRUT(value)
      case 6:
        return isValidDNI(value)
      default:
        return true
    }
  }

  const isValidAddditionalDocument = (value) => {
    switch (guest.value.additionalDocument?.documentTypeId) {
      case 1:
        return isValidCPF(value)
      case 2:
        return isValidCIE(value)
      case 3:
        return isValidID(value)
      case 4:
        return isValidPassport(value)
      case 5:
        return isValidRUT(value)
      case 6:
        return isValidDNI(value)
      default:
        return true
    }
  }

  const isAllowedName = () => {
    const useSimilarityToEditName =
      personalInfoConfig.value?.rules?.useSimilarityToEditName || false

    if (useSimilarityToEditName === false || guest.value.masterGuest != true)
      return true

    if (guest.value.name == "" || guest.value.lastName == "") return false

    const value = `${guest.value.name} ${guest.value.lastName}`

    const originalFullName = guest.value.extraFields?.originalFullName

    const isValidOriginalFullName =
      originalFullName != null && originalFullName !== ""

    if (isValidOriginalFullName == false) return true

    const similarity = compareTwoStrings(originalFullName, value)

    return similarity > 0.75
  }

  /**
   * Validações dos dados pessoais
   */
  const personalDataRules = computed(() => {
    const validationRules = {
      name: {
        required: withI18nMessage(required),
        maxLength: withI18nMessage(maxLength(100)),
        isAllowedName: withI18nMessage(isAllowedName),
      },
      lastName: {
        required: withI18nMessage(required),
        maxLength: withI18nMessage(maxLength(100)),
        isAllowedName: withI18nMessage(isAllowedName),
      },

      additionalDocument: {},
      documents: {},
      birthdate: {
        required: withI18nMessage(required),
        isValidDate: withI18nMessage(isValidDate),
        isBeforeToday: withI18nMessage(isBeforeToday),
        isYoungerThan110Years: withI18nMessage(isYoungerThan110Years),
      },
      gender: {},
      socialName: {},
      nationalityId: {},
      countryId: {},
      jobId: {},
    }

    if (!config.documentData?.additionalDocument?.rules?.hidden) {
      if (config.documentData?.additionalDocument?.rules?.documentUpload) {
        if (
          guest.value.nationalityId === "BR" &&
          additionalDocument?.value?.defaultGuests?.required &&
          !guest.value.isChild
        ) {
          validationRules.additionalDocument = {
            front: {
              required: helpers.withMessage(
                i18n.tc("validations.additional_validation"),
                required
              ),
            },
            back: {
              required: helpers.withMessage(
                i18n.tc("validations.backImage"),
                required
              ),
            },
          }

          if (defaultAdditionalTypes.value.length > 0) {
            validationRules.additionalDocument.documentNumber = {
              required: withI18nMessage(required),
              isValidDocument: withI18nMessage(isValidAddditionalDocument),
            }
          }
        } else if (
          guest.value.nationalityId !== "BR" &&
          additionalDocument.value.foreignersGuests?.required
        ) {
          validationRules.additionalDocument = {
            front: {
              required: helpers.withMessage(
                i18n.tc("validations.frontImage"),
                required
              ),
            },
          }

          if (foreignAdditionalTypes.value.length > 0) {
            validationRules.additionalDocument.documentNumber = {
              required: withI18nMessage(required),
              isValidDocument: withI18nMessage(isValidAddditionalDocument),
            }
          }
        } else if (
          additionalDocument.value.minorsGuests?.required &&
          guest.value.isChild
        ) {
          validationRules.additionalDocument = {
            front: {
              required: helpers.withMessage(
                i18n.tc("validations.additional_validation"),
                required
              ),
            },
          }

          if (minorsAdditionalTypes.value.length > 0) {
            validationRules.additionalDocument.documentNumber = {
              required: withI18nMessage(required),
              isValidDocument: withI18nMessage(isValidAddditionalDocument),
            }
          }
        }
      } else {
        if (
          guest.value.nationalityId === "BR" &&
          additionalDocument?.value?.defaultGuests?.required &&
          !guest.value.isChild &&
          defaultAdditionalTypes.value.length > 0
        ) {
          validationRules.additionalDocument = {
            documentNumber: {
              required: withI18nMessage(required),
              isValidDocument: withI18nMessage(isValidAddditionalDocument),
            },
          }
        } else if (
          guest.value.nationalityId !== "BR" &&
          additionalDocument.value.foreignersGuests?.required &&
          foreignAdditionalTypes.value.length > 0
        ) {
          validationRules.additionalDocument = {
            documentNumber: {
              required: withI18nMessage(required),
              isValidDocument: withI18nMessage(isValidAddditionalDocument),
            },
          }
        } else if (
          additionalDocument.value.minorsGuests?.required &&
          guest.value.isChild &&
          minorsAdditionalTypes.value.length > 0
        ) {
          validationRules.additionalDocument = {
            documentNumber: {
              required: withI18nMessage(required),
              isValidDocument: withI18nMessage(isValidAddditionalDocument),
            },
          }
        }
      }
    }

    if (config.documentData?.mainDocument.rules?.documentUpload) {
      if (guest.value.isChild || guest.value.nationalityId !== "BR") {
        validationRules.documents = {
          front: {
            required: helpers.withMessage(
              i18n.tc("validations.frontImage"),
              required
            ),
          },
        }
      } else {
        validationRules.documents = {
          front: {
            required: helpers.withMessage(
              i18n.tc("validations.frontImage"),
              required
            ),
          },
          back: {
            required: helpers.withMessage(
              i18n.tc("validations.backImage"),
              required
            ),
          },
        }
      }
    }

    if (!guest.value.isChild) {
      validationRules.birthdate = {
        ...validationRules.birthdate,
        olderThanEighteen: withI18nMessage(olderThanEighteen),
      }
    }

    if (guest.value.isChild) {
      validationRules.birthdate = {
        ...validationRules.birthdate,
        youngerThanEighteen: withI18nMessage(youngerThanEighteen),
      }
    }

    if (!config.personalData?.gender?.default) {
      validationRules.gender = {
        required: withI18nMessage(required),
      }
    }

    if (guest.value.informSocialName) {
      validationRules.socialName = {
        required: withI18nMessage(required),
      }
    }

    if (!config.personalData?.nationalityCountry?.default) {
      validationRules.nationalityId = {
        required: withI18nMessage(required),
      }
    }

    if (!config.personalData?.residenceCountry?.default) {
      validationRules.countryId = {
        required: withI18nMessage(required),
      }
    }

    if (!config.personalData?.job?.default && !guest.value.isChild) {
      validationRules.jobName = {
        required: withI18nMessage(required),
      }
    }

    validationRules.documents = Object.assign(validationRules.documents, {
      documentTypeId: {
        required: withI18nMessage(required),
      },
      documentNumber: {
        required: withI18nMessage(required),
        isValidDocument: withI18nMessage(isValidDocument),
      },
    })

    return validationRules
  })

  /**
   * Validações de endereço
   */
  const addressRules = computed(() => {
    const blueTreeGroupId = "8e13d415-f0f9-4758-aeea-14b891df1bee"
    const isBlueTree = groupId.value === blueTreeGroupId

    const validationRules = {
      zipCode: {},
      neighborhood: {},
      stateId: {},
      cityId: {},
      address: {},
      addressNumber: {},
      addressComplement: {},
    }

    if (guest.value.countryId === "BR") {
      if (!config.addressData?.zipcode?.default) {
        validationRules.zipCode = {
          required: withI18nMessage(required),
          minLength: withI18nMessage(minLength(9)),
        }
      }

      if (!config.addressData?.state?.default) {
        validationRules.stateId = {
          required: withI18nMessage(required),
        }
      }

      if (!config.addressData?.city?.default) {
        validationRules.cityId = {
          required: withI18nMessage(required),
        }
      }

      if (!config.addressData?.neighborhood?.default) {
        validationRules.neighborhood = {
          required: withI18nMessage(required),
        }
      }
    }

    if (!config.addressData?.address?.default) {
      validationRules.address = {
        required: withI18nMessage(required),
      }

      if (isBlueTree) {
        validationRules.address.maxLength = withI18nMessage(maxLength(50))
      }
    }

    if (!config.addressData?.addressNumber?.default) {
      validationRules.addressNumber = {
        required: withI18nMessage(required),
      }

      if (isBlueTree) {
        validationRules.addressNumber.maxLength = withI18nMessage(maxLength(8))
      }
    }

    return validationRules
  })

  /**
   * Validações dos contatos
   */
  const contactRules = computed(() => {
    const validationRules = {
      email: {},
      mobilePhoneNumber: {},
      phoneNumber: {},
    }

    if (!guest.value.isChild) {
      validationRules.email = {
        required: withI18nMessage(required),
        email: withI18nMessage(email),
      }

      if (!config.contact?.mobileNumber?.default) {
        validationRules.mobilePhoneNumber = {
          required: withI18nMessage(required),
          isValidMobilePhone: withI18nMessage(isValidMobilePhone(countries)),
        }
      }

      if (!config.contact?.phoneNumber?.default) {
        validationRules.phoneNumber = {
          isValidPhone: withI18nMessage(isValidPhone(countries)),
        }
      }
    }

    return validationRules
  })

  const searchCepRules = computed(() => {
    const validationRules = {
      address: {},
      city: {},
      uf: {},
    }

    validationRules.address = {
      required: withI18nMessage(required),
    }
    validationRules.city = {
      required: withI18nMessage(required),
    }
    validationRules.uf = {
      required: withI18nMessage(required),
    }

    return validationRules
  })

  return {
    getErrorMessages,
    personalDataRules,
    contactRules,
    addressRules,
    searchCepRules,
  }
}
